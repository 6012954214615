@import "./variables";

.navbar {
  width: 100%;
  height: 8rem;
  line-height: 7rem;
  position: fixed;
  max-width: 2000px;
  padding: 0 3rem;
  background-color: var(--background-light);
  border-bottom: var(--text-color) solid 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  z-index: 99;

  .menu-icon {
    cursor: pointer;
    display: none;
    font-size: 40px;
    color: #498ba1;
  }
  img {
    display: flex;
    width: 80px;
  }

  .links {
    display: flex;
    gap: 2rem;
    align-items: center;
    padding-right: 30px;

    .link {
      cursor: pointer;
      color: var(--text-color);
      transition: all 0.3s ease-in-out;
    }
    .link:hover {
      color: var(--text-link);
    }
    .lang {
      display: flex;
      gap: 1rem;
      button {
        color: var(--text-color);
      }
      button.disabled {
        color: var(--text-color);
        opacity: 50%;
      }
    }
    .darkmode-toggle {
      color: var(--text-color);
    }
  }
}

@media screen and (max-width: 900px) {
  .navbar {
    .links {
      width: 300px;
      height: 100vh;
      background-color: var(--background-light);
      position: absolute;
      top: 8rem;
      left: -500px;
      transition: 300ms ease left;
      flex-direction: column;
      align-items: start;
      padding-left: 40px;
      gap: 0.1rem;
      &.active {
        left: 0px;
      }

      .lang {
        display: flex;
        gap: 1rem;
        margin-top: 20px;
      }
      .darkmode-toggle {
        margin-top: 30px;
      }
    }

    .menu-icon {
      display: flex;
    }
  }
}
