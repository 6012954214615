@import "./variables";

.about {
  grid-column: container-start / container-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 10rem;
  background-color: var(--white);
}

@media screen and (max-width: 800px) {
  .about {
    padding: 5rem 2rem;
  }
}
