@import "./variables";

.blogs {
  grid-column: container-start / container-end;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15vh 4vw;
  background-color: #f5f7f6;
  height: 100%;
  p {
    padding: 1rem 4rem;
  }

  .blogs-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // display: flex;
    padding: 4rem 4vw 4rem 4vw;
    gap: 2rem;
    .blog {
      background-color: rgb(255, 255, 255);
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      h3 {
        text-align: center;
        padding: 0.5rem 1rem;
      }
      p {
        font-size: 14px;
        padding: 0.5rem 1rem;
      }
      .img-container {
        width: 100%;
        height: 200px;
        display: flex;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .blogs {
    .blogs-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 4rem 4vw 4rem 4vw;
      gap: 2rem;
      .blog {
        .img-container {
          height: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .blogs {
    .blogs-container {
      display: grid;
      grid-template-columns: 1fr;
      padding: 4rem 4vw 4rem 4vw;
      gap: 2rem;
      .blog {
        .img-container {
          height: 230px;
        }
      }
    }
  }
}
