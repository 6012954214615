@import "./variables";

.products {
  grid-column: container-start / container-end;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
  grid-gap: 2rem;
  place-content: center;
  padding: 1vh 4vw 6vh 4vw;
  background-color: var(--white);

  .product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 3vh;
    background-color: var(--background);
    color: var(--text-color);

    padding: 2rem;
    border-radius: 1rem;
    .header {
      display: flex;
      line-height: normal;
      align-items: flex-end;
      img {
        width: 100px;
      }
    }
    p {
      text-align: center;
    }
  }
}

@media screen and (max-width: 500px) {
  .products {
    padding: 1rem;
    gap: 1rem;
  }
}
