.footer {
  grid-column: container-start / container-end;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 10px;
  height: 52px;
  border-top: 1px solid var(--background);
  background-color: var(--background-light);

  p {
    font-size: 12px;
    letter-spacing: 0.3px;
  }
}
